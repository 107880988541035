"use client";
import React from "react";
import { usePathname } from "next/navigation";
import type { Content } from "@prismicio/client";

import NavLink from "@/components/Navigation/NavigationMobile/NavLink";
import { cleanLink, NavigationLink } from "@/utils/navigation";

type Props = Pick<Content.NavigationMenuDocumentData, "links">;

const Links: React.FC<Props> = ({ links }) => {
  const pathname = usePathname();

  const cleanedLinks = links.map((link) =>
    cleanLink(link as NavigationLink, pathname!),
  );

  return cleanedLinks.map(({ label, href, ...props }, i) => (
    <NavLink key={`link_${i}_${href}`} href={href} {...props}>
      {label}
    </NavLink>
  ));
};

export default Links;
