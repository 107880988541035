"use client";

import Cookies from "js-cookie";

type DummySession = {
  data?: string;
};

const SHOPIFY_SESSION_FLAG = "_cgc_isLoggedIn";

export const hasShopifySession = () => Cookies.get(SHOPIFY_SESSION_FLAG);

export const isActiveSubscriber = () =>
  Cookies.get(SHOPIFY_SESSION_FLAG) === "activeSubscriber";

export function useSession(): DummySession {
  return { data: hasShopifySession() };
}

export function getLoginLink() {
  let returnUrl: string | undefined;
  try {
    returnUrl = encodeURIComponent(window.location.href);
  } catch (e) {
    /* empty */
  }

  return `https://club.craftginclub.co.uk/account/login${
    returnUrl ? `?return_url=${returnUrl}` : ""
  }`;
}

export function getLogoutLink() {
  return "https://club.craftginclub.co.uk/account/logout";
}
