"use client";
import { useEffect } from "react";

import { useModal } from "@cgc/ui/modal";
import { DiscountForm } from "@/components/discounts/DiscountModal/Form";
import { useHash } from "@/utils/useHash";

export const DISCOUNT_MODAL_HASH = "got-a-discount";

export function DiscountModal() {
  const { Modal, setIsOpen } = useModal();
  const { hash, reset } = useHash(DISCOUNT_MODAL_HASH);

  useEffect(() => {
    if (hash) {
      setIsOpen(true);
    }
  }, [hash]);

  return (
    <Modal className="max-w-[360px]" isOpen={!!hash} onClose={reset}>
      <DiscountForm />
    </Modal>
  );
}
