import React, { PropsWithChildren, useState } from "react";
import Link from "@/components/Link";

import { Paragraph, ParagraphSizes } from "@cgc/ui";
import { clsx } from "@cgc/ui";
import { MenuLink } from "@/utils/navigation";

type NavLinkProps = PropsWithChildren<{
  href: string;
  isActive?: boolean;
  links?: MenuLink[] | null;
  className?: string;
  size?: ParagraphSizes;
}>;

export const NavLink: React.FC<NavLinkProps> = ({
  href,
  children,
  links,
  className,
  size = "sub2",
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <Link
        href={href}
        className="plain"
        {...{
          ...(links
            ? {
                onClick: (event) => {
                  event.preventDefault();
                  setExpanded(!expanded);
                },
              }
            : null),
        }}
      >
        <Paragraph
          as="div"
          size={size}
          className={clsx("px-5 py-2.5 uppercase", className)}
        >
          {children}
        </Paragraph>
      </Link>
      {links && expanded && (
        <div className="border-cgc-green-40 bg-cgc-green-10 border-y-2">
          {links.map((link, i) => (
            <NavLink key={`sub_${link.href}_${i}`} {...link} size="p1">
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default NavLink;
