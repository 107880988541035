import type { JSX, ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { clsx } from "../utils/clsx";
import { Button } from "./button";

interface DrawerProps {
  header?: ReactNode;
  children: ReactNode;
  isVisible: boolean;
  setVisible: (state: boolean) => void;
  className?: string;
}

function Overlay({ onClick }: { onClick: () => void }): JSX.Element {
  // eslint-disable-next-line -- this is overlay and we don't want to role it
  return <div className="h-full w-screen cursor-pointer" onClick={onClick} />;
}

export function Drawer({
  header,
  children,
  isVisible,
  setVisible,
  className,
}: DrawerProps): JSX.Element {
  return (
    <div
      className={clsx(
        "fixed inset-0 z-50 transform overflow-hidden bg-gray-900 bg-opacity-25 ease-in-out",
        isVisible
          ? "translate-x-0 opacity-100 transition-opacity duration-500"
          : "translate-x-full opacity-0 transition-all delay-500",
        className,
      )}
    >
      <div
        className={clsx(
          "delay-400 absolute right-0 h-full w-screen max-w-lg transform bg-white shadow-xl transition-all duration-500 ease-in-out",
          isVisible ? " translate-x-0 " : " translate-x-full ",
        )}
      >
        <article className="relative flex h-full w-screen max-w-lg flex-col space-y-2 overflow-y-scroll">
          {header ? (
            <div className="flex flex-row items-center justify-between px-5 py-3">
              <div className="text-lg font-bold">{header}</div>
              <Button
                color="link"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <XMarkIcon className="h-6 w-6 text-black" />
                <span className="sr-only">Close menu</span>
              </Button>
            </div>
          ) : null}
          {children}
        </article>
      </div>
      <Overlay
        onClick={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}
