import React from "react";
import dynamic from "next/dynamic";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Button, ButtonProps } from "@cgc/ui";

const AccountMenuButton: React.FC<ButtonProps> = (props) => (
  <Button
    color="unstyled"
    aria-label="Account menu"
    className="px-2 py-2"
    {...props}
  >
    <UserCircleIcon className="nav-icon" />
  </Button>
);

const AccountMenu = dynamic(
  () => import("@/components/Navigation/AccountMenu/AccountMenu"),
  {
    ssr: false,
    loading: () => <AccountMenuButton disabled />,
  },
);

export default AccountMenu;
