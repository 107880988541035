import {
  NavigationMenuDocument,
  NavigationMenuDocumentDataLinksItem,
} from "@/prismicio-types";
import * as prismicH from "@prismicio/helpers";
import { LinkField } from "@prismicio/types";
import { Simplify } from "type-fest";

export const renderPrismicLink = (link: LinkField): string | null =>
  prismicH.asLink(link);

export type NavigationLink = Simplify<NavigationMenuDocumentDataLinksItem> & {
  submenu?: NavigationMenuDocument;
};

export type MenuLink = {
  label: string;
  href: string;
  isActive: boolean;
  links: MenuLink[] | null;
};

export const cleanLink = (
  navLink: NavigationLink,
  currentPath: string,
): MenuLink => {
  const { link, label, submenu } = navLink;
  let links: MenuLink[] | null = null;
  if (submenu.id) {
    links = submenu.data.links.map((link: any) => cleanLink(link, currentPath));
  }

  const href = renderPrismicLink(link) ?? "/";
  let isActive = href !== "/" && href === currentPath; //|| (href.length > 1 && currentPath.startsWith(href));
  if (!isActive && links) {
    isActive = !!links.find(({ isActive }) => isActive);
  }

  return {
    label: label as string,
    href,
    isActive, // ,
    links,
  };
};
