"use client";
import React from "react";
import { GlobeAltIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import * as prismicH from "@prismicio/helpers";
import Image from "next/image";

import { DropdownMenu } from "@cgc/ui/client-components";
import { Paragraph, clsx } from "@cgc/ui";
import { SettingsDocumentDataSiteLocalesItem } from "@/prismicio-types";

const Icon: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <div className="relative mr-2.5 h-[26px] w-[26px] overflow-hidden rounded-full">
    <Image className="object-cover" src={src} alt={alt} fill />
  </div>
);

type Props = {
  locales: SettingsDocumentDataSiteLocalesItem[];
};

const LocaleMenu: React.FC<Props> = ({ locales }) => {
  const items = locales.filter((item) => item.active);

  return (
    <DropdownMenu
      className="w-60"
      button={({ open, ...props }) => (
        <DropdownMenu.Button
          className={clsx(
            "h-full appearance-none px-2 py-2",
            open && "bg-cgc-green-20",
          )}
          aria-label="Global sites menu"
          {...props}
        >
          {items.length ? (
            items[0].icon && (
              <div className="mr-2.5 flex items-center">
                <div className="flex h-[30px] w-[30px] items-center justify-center">
                  <div className="relative h-6 w-6 overflow-hidden rounded-full">
                    <Image
                      className="object-cover"
                      src={prismicH.asImageSrc(items[0].icon)!}
                      alt={(items[0].label as string) ?? ""}
                      fill
                    />
                  </div>
                </div>

                <ChevronDownIcon
                  className={clsx(
                    "nav-icon !text-cgc-grey ml-2 mt-0.5 h-3 w-3 stroke-2",
                  )}
                />
              </div>
            )
          ) : (
            <GlobeAltIcon className={clsx("nav-icon !text-cgc-green")} />
          )}
        </DropdownMenu.Button>
      )}
    >
      {items.map(({ icon, label, link }) => (
        <DropdownMenu.Item key={prismicH.asLink(link)}>
          <a href={prismicH.asLink(link) ?? "/"} className="plain">
            <Paragraph
              as="div"
              size="p1"
              className="flex flex-row items-center"
            >
              {icon && (
                <Icon
                  src={prismicH.asImageSrc(icon)!}
                  alt={(label as string) ?? ""}
                />
              )}{" "}
              {label}
            </Paragraph>
          </a>
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  );
};

export default LocaleMenu;
