import type { JSX, PropsWithChildren } from "react";
import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { clsx } from "../utils/clsx";

type DropdownMenuItemProps = PropsWithChildren<{
  activeClassName?: string;
}>;

type DropdownMenuButtonProps = PropsWithChildren<{
  className?: string;
}>;

interface DropdownMenuProps {
  button: ({
    open,
    onClick,
  }: {
    open: boolean;
    onClick: () => void;
  }) => React.ReactNode;
  children: React.ReactNode;
  className?: string;
  open?: boolean;
}

export function DropdownMenu({
  button,
  children,
  className,
  open = false,
}: DropdownMenuProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Menu
      as="div"
      className="relative inline-block"
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <>
        {button({
          open: isOpen,
          onClick: () => {
            setIsOpen(!isOpen);
          },
        })}
        {isOpen ? (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show
          >
            <Menu.Items
              className={clsx(
                "bg-cgc-green-20 absolute right-0 z-10 z-50 -mt-1 origin-top-right px-3 py-2 shadow-lg focus:outline-none",
                className,
              )}
              static
            >
              <div>{children}</div>
            </Menu.Items>
          </Transition>
        ) : null}
      </>
    </Menu>
  );
}

function Item({
  children,
  activeClassName,
}: DropdownMenuItemProps): JSX.Element {
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          className={clsx(
            "flex flex-row items-center py-1.5",
            active && activeClassName,
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
}

DropdownMenu.Item = Item;

function Button({
  children,
  className,
  ...props
}: DropdownMenuButtonProps): JSX.Element {
  return (
    <Menu.Button className={clsx(className)} {...props}>
      {children}
    </Menu.Button>
  );
}

DropdownMenu.Button = Button;
