"use client";
import { useEffect, useState } from "react";

export function getHash(): string | null {
  return typeof window !== "undefined"
    ? decodeURIComponent(window.location.hash.replace("#", ""))
    : null;
}

export function useHash(lookingHash: string) {
  const [hash, setHash] = useState<string | null>(getHash());
  useEffect(() => {
    const handleHashChange = () => {
      const hash = getHash();
      setHash(hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return {
    hash: hash === lookingHash ? hash : null,
    reset: () => (window.location.hash = ""),
  };
}
