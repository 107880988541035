"use client";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";

import NavLink from "@/components/Navigation/NavigationMobile/NavLink";

const LoginLink = () => {
  const [copy, setCopy] = useState("Log In");
  const [link, setLink] = useState("/auth/login");
  const { data: session } = useSession();

  useEffect(() => {
    if (session) {
      setCopy("Logout");
      setLink("/auth/logout");
    }
  }, [session]);

  return <NavLink href={link}>{copy}</NavLink>;
};

export default LoginLink;
