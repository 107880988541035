"use client";
import React, { useEffect, useState } from "react";
// import { useSession } from "next-auth/react";
import { isActiveSubscriber } from "@/data/auth/session";
import { useSession } from "next-auth/react";
import { Button } from "@cgc/ui";
// import { TAGS } from "@/data/utils/tags";
import Link from "@/components/Link";

const DEFAULT_LINK = "https://club.craftginclub.co.uk/pages/sign-up";

const JoinButton = () => {
  const [link, setLink] = useState(DEFAULT_LINK);
  const [copy, setCopy] = useState("Join the club");
  const { data: session } = useSession();

  const className = "px-[12px] lg:w-[145px]";

  useEffect(() => {
    if (isActiveSubscriber()) {
      setCopy("Refer a friend");
      setLink("https://club.craftginclub.co.uk/ginpals");
    } else if (session) {
      setCopy("Rejoin");
      setLink("/members-area/subscriptions");
    }
  }, [session]);

  return (
    <Link href={link}>
      <Button color="secondary" className={className} outline>
        {copy}
      </Button>
    </Link>
  );
};

export default JoinButton;
